import { Directive } from '@angular/core';

import { ButtonBase } from './button-base';

/** Applies styles for the button. */
@Directive({
	selector: 'button[wndrcButton], a[wndrcButton]',
	standalone: true,
})
export class ButtonDirective extends ButtonBase {}
